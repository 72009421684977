import * as moment from 'moment';

export class FilterValueToConditionsTransformer
{
    /*public*/ transform(value, property, type)
    {
        if (!value) {
            return value;
        }

        let object = {};

        switch (type) {
            case 'boolean':
                if (value === 'no') {
                    object[property] = {$eq: false};
                } else if (value === 'yes') {
                    object[property] = {$eq: true};
                }
                break;

            case 'phone':
                object[property] = {$phone: value};
                break;

            case 'text':
            case 'address':
                object[property] = {$match: value};
                break;

            case 'int-range':
                if (!value.from && !value.to) {
                    object = null;
                    break;
                }

                object[property] = {};

                if (value.from || value.from === 0) {
                    object[property]['$gte'] = parseInt(value.from);
                }

                if (value.to || value.to === 0) {
                    object[property]['$lte'] = parseInt(value.to);
                }
                break;

            case 'money-range':
                if (!value.from && !value.to) {
                    object = null;
                    break;
                }

                object[property+'.amount'] = {};

                if (value.from || value.from === 0.0) {
                    object[property+'.amount']['$gte'] = parseFloat(value.from) * 100;
                }

                if (value.to || value.to === 0.0) {
                    object[property+'.amount']['$lte'] = parseFloat(value.to) * 100;
                }
                break;

            case 'float-range':
                if (!value.from && !value.to) {
                    object = null;
                    break;
                }

                object[property] = {};

                if (value.from || value.from === 0.0) {
                    object[property]['$gte'] = parseFloat(value.from);
                }

                if (value.to || value.to === 0.0) {
                    object[property]['$lte'] = parseFloat(value.to);
                }
                break;

            case 'number':
                object[property] = {$eq: value};
                break;

            case 'tags':
                if ((!value.include || value.include.length === 0) &&
                    (!value.exclude || value.exclude.length === 0)) {
                    object = null;
                    break;
                }

                object[property] = {};

                if (value.include && value.include.length !== 0) {
                    object[property]['$in'] = value.include;
                }

                if (value.exclude && value.exclude.length !== 0) {
                    object[property]['$nin'] = value.exclude;
                }

                break;

            case 'physical-size':
                if (!value.from && !value.to) {
                    object = null;
                    break;
                }

                object[property] = {};

                if (value.from) {
                    // We always calculate size in megabytes
                    object[property]['$gte'] = parseFloat(value.from) * 1024 * 1024;
                }

                if (value.to) {
                    object[property]['$lte'] = parseFloat(value.to) * 1024 * 1024;
                }

                break;

            case 'date-range':
                if (!value.fromDate && !value.toDate) {
                    object = null;
                    break;
                }

                object[property] = {};

                if (value.fromDate) {
                    object[property]['$gte'] = moment(value.fromDate).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
                }

                if (value.toDate) {
                    object[property]['$lte'] = moment(value.toDate).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
                }
                break;

            case 'date':
            case 'dueDate':
                let start = moment(value).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
                let end = moment(value).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ');

                object[property] = {$gte: start, $lte: end};
                break;

            case 'choice':
                if (!value || value.length === 0) {
                    object = null;
                    break;
                }

                object[property] = {$in: value};
                break;

            default:
                object[property] = {$in: value};
        }

        return object;
    }
}
