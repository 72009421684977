import {bindable, customElement, computedFrom} from "aurelia-framework";
import {UrlUtils} from "../../utilities/url-utils";

import "./pagination.less";

@customElement('sio-pagination')
export class Pagination {
    @bindable conditions;
    @bindable sort;

    @bindable offset;
    @bindable limit;
    @bindable total;
    //Total is last reachable result (e.g. in ES 10.000), total is result count
    @bindable all;

    @bindable change;

    @computedFrom('offset', 'limit', 'total')
    get pagination() {
        const maxPagesCountInOneDirection = 3;
        const currentPage = Math.floor(this.offset / this.limit) + 1;
        const lastPage = Math.ceil(this.total / this.limit) || 1;

        let pagination = {
            total: this.total,
            all: this.all && this.all > this.total ? this.all : null,
            offset: this.offset,
            limit: this.limit,
            currentPage: currentPage,
            firstPage: 1,
            lastPage: lastPage,
            firstItem: (currentPage - 1) * this.limit + 1,
            lastItem: currentPage === lastPage ? this.total : currentPage * this.limit,
        };

        let counterNext = pagination.currentPage + 1;
        let nextPages = [];
        while (counterNext <= pagination.lastPage && nextPages.length < maxPagesCountInOneDirection) {
            nextPages.push(counterNext);
            counterNext++;
        }
        pagination.nextPages = nextPages;

        if (pagination.lastPage - pagination.currentPage > maxPagesCountInOneDirection) {
            pagination.showThreeDotsNext = true;
        }

        let counterPrevious = pagination.currentPage - 1;
        let previousPages = [];
        while (counterPrevious >= 1 && previousPages.length < maxPagesCountInOneDirection) {
            previousPages.push(counterPrevious);
            counterPrevious--;
        }
        pagination.previousPages = previousPages.reverse();

        if (pagination.currentPage > maxPagesCountInOneDirection + 1) {
            pagination.showThreeDotsPrev = true;
        }

        return pagination;
    }

    pageUrl(index, conditions, sort, disabled)
    {
        if (index > this.pagination.lastPage || index < this.pagination.firstPage || disabled) {
            return 'javascript:void(0);'
        }

        return UrlUtils.upsertQueryParamValue(window.location.href, "offset", (index - 1) * this.limit, false);
    }

    changePage(index, disabled)
    {
        if (index > this.pagination.lastPage || index < this.pagination.firstPage || disabled) {
            return;
        }

        this.change((index - 1) * this.limit);
    }
}
