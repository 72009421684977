import {LogManager, inject} from 'aurelia-framework';
import * as _ from 'lodash';
import {BindingSignaler} from "aurelia-templating-resources";
import {MongoDataLoader} from '../loader/mongo-data-loader';
import {ElasticSearchDataLoader} from '../loader/elasticsearch-data-loader';
import {UrlUtils} from "../../utilities/url-utils";
import {WindowUtils} from "../../utilities/window-utils";

const logger = LogManager.getLogger('table');

/**
 * Handles requesting data via loaders
 */
@inject(
    MongoDataLoader,
    ElasticSearchDataLoader,
    BindingSignaler
)
export class TableService
{
    additionalFetchConditions;
    contextObjectRef;
    config;
    saveFiltersToUrl;
    initialLoading = true;

    request = {
        offset: 0,
        limit: 50,
        sort: [],
        conditions: {},
        search: null
    };

    callbacks = {
        onLoadStart: () => {},
        onLoadSuccess: (data) => {},
        onLoadError: () => {},
    };

    loading = false;
    error;

    constructor(
        mongoDataLoader,
        esDataLoader,
        signaler
    ) {
        this.mongoDataLoader = mongoDataLoader;
        this.esDataLoader = esDataLoader;
        this.signaler = signaler;
    }

    load()
    {
        let request = _.cloneDeep(this.request);

        if (this.additionalFetchConditions && _.keys(this.additionalFetchConditions).length > 0) {
            request.conditions = {
                '$and': [
                    this.request.conditions,
                    this.additionalFetchConditions
                ]
            };
        }

        if (request.search) {
            request.conditions.search = request.search;
            delete request.search;
        }

        logger.debug('Fetching data', request);

        let loader = this.mongoDataLoader;

        if (this.config.elasticsearch || request.conditions.search) {
            loader = this.esDataLoader;
        }

        request.embeds = this.config.embeds ? _.cloneDeep(this.config.embeds) : [];

        if (this.contextObjectRef) {
            request.context = {id: this.contextObjectRef.id, modelId: this.contextObjectRef.modelId};
        }

        _.each(this.config.visibleColumns, (column) => {
            if (column.embeds) {
                request.embeds.push(column.embeds);
            }
        });

        if (this.abortController) {
            this.abortController.abort();
        }

        this.callbacks.onLoadStart();
        this.loading = true;
        this.error = null;
        this.abortController = new AbortController();
        let cancelled = false;

        if (this.saveFiltersToUrl) {

            if (!this.initialLoading) {
                let newurl = UrlUtils.upsertQueryParamValue(
                    window.location.href,
                    'search',
                    this.request.search ? this.request.search : '',
                    false
                );

                newurl = UrlUtils.upsertQueryParamValue(
                    newurl,
                    'sortBy',
                    JSON.stringify(this.request.sort),
                    false
                );

                newurl = UrlUtils.upsertQueryParamValue(
                    newurl,
                    'offset',
                    this.request.offset,
                    false
                );

                WindowUtils.updateLocationHrefWithoutReloading(newurl);
            } else {
                WindowUtils.updateRedirectLocationHref(window.location.href);
            }
        }

        const onAbort = () => {
            cancelled = true;
        }

        this.abortController.signal.addEventListener('abort', onAbort, {once: true});

        this.initialLoading = false;

        loader.getData(this.config.modelId, request).then(async (data) => {

            logger.debug('Received data', data);

            if (cancelled) {
                return;
            }

            this.callbacks.onLoadSuccess(data);
            this.loading = false;
            this.abortController.signal.removeEventListener('abort', onAbort);

        }, (error) => {

            if (cancelled) {
                return;
            }

            this.callbacks.onLoadError(error);
            this.loading = false;
            this.error = error.status == 403 ? 'sio.access_denied' : 'list.load_error';
            this.abortController.signal.removeEventListener('abort', onAbort);

            console.log('Error', error);

        });
    }

    /**
     * Changes offset and reloads
     */
    changeOffset(offset)
    {
        this.request.offset = offset;

        this.load();
    }

    /**
     * Performs a search with the given search string and reloads
     */
    search(search)
    {
        if (search === '') {
            this.request.sort = this.config.defaultSort || [];
        } else {
            this.request.sort = [];
        }

        this.request.offset = 0;
        this.request.search = search;
        this.signaler.signal('sio-table-sort-update');

        this.load();
    }

    setFilters(conditions, reload) {
        this.request.conditions = conditions;
        this.request.offset = 0;

        if (reload) {
            this.load();
        }
    }

    setCurrentRenderer(renderer, reload = false) {

        this.request.currentRenderer = renderer;
        if (reload) {
            this.load();
        }
    }

    /**
     * Returns sort direction for property
     */
    sortForProperty(property) {
        if (!property) {
            return null;
        }

        for (let sort of this.request.sort) {
            if (sort[0] === property) {
                return sort[1];
            }
        }

        return null;
    }

    /**
     * Toggles sort for column
     */
    toggleSort(column)
    {
        if (column.sortable === false || !column.property) {
            return;
        }

        let sort    = this.sortForProperty(column.sortProperty || column.property);
        let newSort = sort === 'ASC' ? 'DESC' : 'ASC';

        this.request.sort = [
            [
                column.sortProperty || column.property,
                newSort,
            ]
        ];
        this.request.offset = 0;

        this.signaler.signal('sio-table-sort-update');
        this.load();
    }
}
