import {inject} from 'aurelia-framework';
import * as _ from 'lodash';
import {Client} from '../../api/client';

@inject(Client)
export class ConfigurationLoader
{
    constructor(client)
    {
        this.client = client;
    }

    get(config, viewContext)
    {
        if (_.isString(config)) {

            let queryParams = '';
            if (viewContext) {
                queryParams = '?viewContext='+viewContext;
            }

            return this.client.get(config + '/list' + queryParams, 60);

        } else if (_.isObject(config)) {
            return Promise.resolve(config);
        } else {
            return Promise.reject(new Error(`Invalid configuration "${config}".`));
        }
    }
}
